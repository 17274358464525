<template>
  <div class="page-container">
    <div class="container">
      <h3 class="title-2 pt-4">
        {{ $t('assetsAvailability').toUpperCase() }}
      </h3>
      <b-row align-v="center" class="row pb-3">
        <div class="col-md-auto">
          <b-form-group
            :label="$t('search')"
            label-for="search"
            label-cols="4"
            content-cols="0"
            label-size="sm"
            class="mb-0"
            align="left"
          >
            <b-form-input
              id="search"
              v-model="search"
              placeholder="Enter assets name"
              size="sm"
            ></b-form-input>
          </b-form-group>
        </div>
        <div class="col-md-auto my-2">
          <datetime
            id="reservation-date"
            v-model="date"
            input-class="form-control form-control-sm"
          ></datetime>
        </div>
        <div class="col-lg-auto">
          <b-button
            v-if="assets.length"
            @click="csvExport(assetsForExport(), 'assets-details')"
          >
            {{ $t('export') }}
          </b-button>
        </div>
      </b-row>
      <div>
        <b-table
          striped
          hover
          :items="filteredAssets"
          :fields="fields"
          show-empty
          bordered
          @row-clicked="handleRowClicked"
        >
          <template #cell(sr)="{item: {id}}">
            {{ getSymbol(id) }}
          </template>
          <template #cell(asset_name)="data">
            {{ data.value }}
          </template>
          <template #cell(org_name)="data">
            {{ data.value }}
          </template>
          <template #cell(org_path)="data">
            {{ data.value }}
          </template>

          <template #row-details="{item: {reservations}}">
            <table
              v-if="reservations.length"
              style="width:100%; background-color: white;"
            >
              <tr>
                <th
                  v-for="(field, index) of detailFields"
                  :key="index"
                >
                  {{ field.label }}
                </th>
              </tr>

              <tr v-for="(item, index) of reservations" :key="index">
                <td
                  v-for="(col, colIndex) in detailFields"
                  :key="`${col.key}-${colIndex}`"
                >
                  {{ getContent(item, col.key) }}
                </td>
              </tr>
            </table>

            <div v-else class="text-center">
              Records not found
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import OkkuApi from '@/services/OkkuApi'
import { exportAsCSVMixin } from '@/mixins/mixins'
import { mapGetters } from 'vuex'
import { Datetime } from 'vue-datetime'
import * as moment from 'moment'

export default {
  name: 'AssetsAvailability',
  components: { Datetime },
  mixins: [exportAsCSVMixin],
  data() {
    return {
      date: moment().toISOString(),
      assets: [],
      filteredAssets: [],
      openedItem: null,
      search: '',
      fields: [
        {
          key: 'sr',
          label: '#'
        },
        {
          key: 'name',
          label: this.$t('assetName')
        },
        {
          key: 'org',
          label: this.$t('orgName')
        },
        {
          key: 'org_path',
          label: this.$t('building')
        }
      ],
      detailFields: [
        {
          key: 'spot',
          label: `${this.$t('room')}/${this.$t('desk')}`
        },
        {
          key: 'floor',
          label: this.$t('floor')
        },
        {
          key: 'reservation_date',
          label: this.$t('reservation_date')
        },
        {
          key: 'start_time',
          label: this.$t('startTime')
        },
        {
          key: 'end_time',
          label: this.$t('endTime')
        },
        {
          key: 'qty',
          label: this.$t('qty')
        },
        {
          key: 'comment',
          label: this.$t('comment')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('common', ['buildingTimezone'])
  },
  watch: {
    search(val) {
      if (val) {
        const searchKey = val.toString().toLowerCase()
        const filteredAssets = []
        const assets = [...this.assets]
        this.emptyItem()

        assets.forEach(asset => {
          const { name } = asset
          if (
            name
              .toString()
              .toLowerCase()
              .includes(searchKey)
          ) {
            filteredAssets.push(asset)
          }
          // Set filtered records
          this.filteredAssets = filteredAssets
        })
      } else {
        this.filteredAssets = [...this.assets]
      }
    },
    date(val) {
      if (val && Object.keys(val).length) {
        this.emptyItem()
        this.getAssetAvailabilityDetails(val)
      }
    }
  },
  methods: {
    handleRowClicked(item) {
      if (this.openedItem && this.openedItem.id === item.id) {
        this.openedItem = null
        this.$set(item, '_showDetails', false)
      } else {
        if (this.openedItem && this.openedItem.id !== item.id) {
          this.$set(this.openedItem, '_showDetails', false)
        }
        this.openedItem = item
        this.$set(item, '_showDetails', true)
      }
    },
    emptyItem() {
      if (this.openedItem) {
        this.$set(this.openedItem, '_showDetails', false)
        this.openedItem = null
      }
    },
    assetsForExport() {
      const assetForExports = []
      this.assets.forEach(asset => {
        if (asset.reservations.length) {
          asset.reservations.forEach(reservation => {
            const {
              id,
              reservations,
              _showDetails,
              ...resAssets
            } = asset
            delete reservation.asset_id
            assetForExports.push({ ...resAssets, ...reservation })
          })
        } else {
          const {
            id,
            reservations,
            _showDetails,
            ...resAssets
          } = asset
          const emptyReservations = {
            comment: '',
            end_datetime: '',
            floor: '',
            qty: '',
            spot: '',
            start_datetime: ''
          }
          assetForExports.push({ ...resAssets, ...emptyReservations })
        }
      })
      return assetForExports
    },
    async getAssetAvailabilityDetails(date) {
      try {
        const assets = await OkkuApi.getAssetAvailabilityDetails(date)
        this.assets = assets
        this.filteredAssets = assets
      } catch (error) {
        this.assets = []
      }
    },
    formatDate(date) {
      if (date) {
        return moment(String(date)).format('ddd DD-MMM HH:mm')
      }
      return date
    },
    getContent(item, key) {
      if (
        key === 'reservation_date' ||
        key === 'start_time' ||
        key === 'end_time'
      ) {
        let date = item.start_datetime
        let format = 'HH:mm'

        if (key === 'reservation_date') {
          format = 'ddd DD-MMM'
        }
        if (key === 'end_time') {
          date = item.end_datetime
        }

        return moment(date)
          .tz(this.buildingTimezone)
          .format(format)
      }
      return item[key]
    },
    getSymbol(id) {
      return this.openedItem && this.openedItem.id === id ? '-' : '+'
    }
  }
}
</script>

<style lang="scss" scoped>
.b-table {
  font-size: smaller;
}
</style>
